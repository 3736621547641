.header {
  position: relative;
}

.navbar,
.navbarMobile {
  background: transparent;
  height: 80px;
  padding-block: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  position: absolute;
  top: 0;
  z-index: 20;
  width: 100%;
}

.logo {
  margin-top: 15px;
  position: relative;
  z-index: 10;
  max-height: 46px;
  width: 155px;
}

.navbar .navigator .menuList {
  display: flex;
  align-items: center;
  gap: 40px;
}

.navigator li {
  position: relative;
  white-space: nowrap;
}

.navigator a,
.navigator span {
  color: #000;
  /* font-weight: 700; */
  transition: all 0.3s ease;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}

.navigator a:hover,
.navigator span:hover {
  color: var(--green-color);
}

.pageActive {
  color: var(--green-color) !important;
}

.menuList a {
  color: #646464;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.page {
  /* cursor: not-allowed; */
  pointer-events: none;
}

ul,
ol {
  list-style: none;
}

.navigator .pageActive {
  position: relative;
  /* font-weight: 700; */
  cursor: pointer;
}

.pageActive::after {
  content: "";
  position: absolute;
  left: -10px;
  transform: translateX(-50%);
  width: 8.1px;
  height: 10.07px;
  background: url("../../Assets/icons/pointer.svg");
  border-radius: 30px;
}

.subMenu,
.subMenuMobile {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  position: absolute;
  top: 100%;
  left: calc(50% - 120px);
  padding-top: 25px;
  width: 240px;
  transition: all 0.2s ease;
  z-index: 12;
}

.navigator li:hover .subMenu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.hamburger,
.hamburgerActive {
  display: none;
}

.langWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 10px;
  width: 70px;
  margin-inline: auto;
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0.1) 0%,
    rgba(217, 217, 217, 0) 100%
  );
  backdrop-filter: blur(16.5px);
  border-radius: 6px;
  border: 1px solid rgba(238, 238, 238, 0.2);
}

.langWrapper:hover {
  cursor: not-allowed;
}

.customButtonStyle {
  color: #36ae7c;
  padding: 12px 22px;
  border: unset;
  border: 1px solid #36ae7c;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(7px);
  border-radius: 3px;
  transition: 0.3s ease all;
  white-space: nowrap;
  display: block;
}
.customButtonStyle:hover {
  background-color: #36ae7c;
  color: #fff;
}

@media screen and (max-width: 1320px) {
  .navigator a,
  .navigator span {
    font-size: 12px;
  }
}
@media screen and (max-width: 991px) {
  .navigator {
    background: white;
  }
  .navigator a,
  .navigator span {
    font-size: 16px;
  }
  /* .customButtonStyle {
    display: none;
  } */
  .navbar .navigator ul,
  .languages {
    display: none;
  }
  .navbarMobile .navigator,
  .navbarMobile .navigatorClose {
    position: fixed;
    top: 0;
    left: 0;
    /* background-color: rgba(0, 0, 0, 0.9); */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.3s ease;
    overflow: hidden;
    z-index: 9;
  }
  .navbarMobile .navigator {
    height: 100%;
    opacity: 1;
    visibility: visible;
  }
  .navbarMobile .navigatorClose {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
  .navbarMobile .navigator .menuList,
  .navbarMobile .navigatorClose .menuList {
    display: flex;

    flex-direction: column;
    gap: 30px;
  }

  .mobileActive .subMenuMobile {
    opacity: 1;
    visibility: visible;
    transform: translateY(10px);
  }
  .hamburger,
  .hamburgerActive {
    position: relative;
    z-index: 10;
    cursor: pointer;
    display: block;
  }
  .hamburger span,
  .hamburgerActive span {
    display: block;
    width: 30px;
    height: 3px;
    background-color: #36ae7c;
    margin: 5px 0;
    transition: all 0.3s ease;
  }
  .hamburgerActive span:nth-child(1) {
    transform: rotate(45deg) translate(6px, 6px);
  }
  .hamburgerActive span:nth-child(2) {
    opacity: 0;
  }
  .hamburgerActive span:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  .menuList {
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    padding-block: 10px;
  }
}
