.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60vh;
}

.notFound h1 {
  color: #fff;
}

.notFound h3 {
  color: rgba(255, 255, 255, 0.64);
}

.topOf {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topOf img {
  width: 178px;
  max-height: 54px;
}

.backBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 35px;
  color: #fff;
  transition: color 0.3s ease;
}

.backBtn:hover {
  color: var(--green-color);
}

.backBtn p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}

@media screen and (max-width: 576px) {
  .topOf {
    flex-direction: column;
    gap: 30px;
  }
}
