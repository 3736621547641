.contactWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.contactWrapper::before{
    content: url("../../../../Assets/images/textBg.png");
    z-index: -100;
    position: absolute;
    top: 0px;
    left: 0;
}
.contact{
   width: 901px;
   padding: 40px 44px;
   background-color: #fff;
   box-shadow: 0px 12px 80px rgba(54, 174, 124, 0.1);
    border-radius: 24px;
}
.contactFormNames , .contactFormInfo{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 33px;
}
.button{
    width: 183px;
    height: 54px;
    background: #36AE7C;
    border: 1px solid #36AE7C;
    border-radius: 68px;
}
.buttonWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.textFieldStyle{
    border: 1px solid #EBEBEB;
    border-radius: 2px;
}
@media screen and (max-width:1200px) {
  .contact{
    width: 100%;
  }
    
}
@media screen and (max-width:991px) {
    .contactWrapper::before{
        content: "";

    }
    .contact{
        padding: 20px;
    }
    .contactFormNames , .contactFormInfo{
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 0px;

    }
}

