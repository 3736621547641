.card {
  text-align: center;
  position: relative;
}

.card img:nth-of-type(1) {
  height: 70px;
}

.card img:nth-of-type(2) {
  position: absolute;
  top: 25px;
  right: -120px;
}

.card h6:nth-of-type(1) {
  margin-block: 40px 20px;
  color: #222222;
}

.card h6:nth-of-type(2) {
  max-width: 290px;
  margin-inline: auto;
  color: #444444;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .card img:nth-of-type(2) {
    width: 130px;
    right: -70px;
  }
  .card img:nth-of-type(2) {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .card img:nth-of-type(2) {
    display: none;
  }
}
