.heading p {
  color: var(--green-color);
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
}

.heading h2 {
  margin-block: 8px 16px;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #222222;
  font-family: "Syne", sans-serif ;
}

.heading h6 {
  max-width: 600px;
  margin-inline: auto;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #444444;
}

@media screen and (max-width: 425px) {
  .heading p{
    font-size: 13px;

  }
  .heading h2{
    font-size: 28px;

  }
  .heading h6{
    font-size: 15px;

  }
}