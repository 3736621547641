.cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

@media screen and (max-width: 991px) {
  .cards {
    grid-template-columns: 1fr;
  }
}
