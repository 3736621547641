.card {
  background: #ffffff;
  border: 2px solid rgba(54, 174, 124, 0.1);
  box-shadow: 0px 12px 80px rgba(54, 174, 124, 0.1);
  border-radius: 24px;
  padding-block: 40px;
}

.picture {
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 250px;
}

.card h4,
.card h6 {
  padding-inline: 40px;
}

.card h4 {
  margin-block: 30px 15px;
  letter-spacing: -0.01em;
  font-feature-settings: "ss06" on;
  color: #222222;
}

.card h6 {
  color: #444444;
}

@media screen and (max-width: 991px) {
  .card {
    text-align: center;
  }
}

@media screen and (max-width: 576px) {
  .card h4,
.card h6 {
  padding-inline: 15px;
}
}