.footer {
  background: #000000;
  padding-block: 25px;
}

.top {
  display: flex;
  row-gap: 50px;
  justify-content: space-between;
}

.company img {
  margin-bottom: 20px;
  cursor: pointer;
  max-height: 46px;
  width: 155px;
}

.company p {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #929292;
}

.company a {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #929292;
}
.company a:hover {
  color: #36ae7c;
}

.siteMap {
  display: flex;
  gap: 40px;
}

.siteMap a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #929292;
  height: 30px;
}

.siteMap a:hover {
  color: #36ae7c;
}

.separator {
  margin-block: 30px;
  width: 100%;
  height: 1px;
  background: #0d0d0d;
}

.bottom {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bottom p,
.bottom a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #929292;
}

.bottom a:hover {
  color: #36ae7c;
}

@media screen and (max-width: 1200px) {
  .siteMap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 991px) {
  .footer {
    text-align: center;
  }
  .top {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .bottom {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .siteMap {
    grid-template-columns: 1fr;
  }
  /* .bottom {
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  } */
}
