.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 70px 30px;
}

@media screen and (max-width: 1200px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .cards {
    grid-template-columns: 1fr;
  }
}
