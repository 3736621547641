

.home section{
  margin-bottom: 100px;
}

.home section:nth-of-type(1){
  margin-bottom: 0px;
}

@media screen and (max-width: 991px){
  .home section:nth-of-type(1){
    margin-bottom: 100px;
  }
}