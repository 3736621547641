.accordionGrid {
  max-width: 1100px;
  margin-inline: auto;
}

/* ------------------  Accordion ----------------------*/

.wrapper {
  /* padding-inline: 30px; */
  display: flex;
  flex-direction: column;
  padding-block: 40px;
  width: 100%;
  border-top: 2px solid #dedede;
}

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  cursor: pointer;
  /* margin-bottom: 30px; */
}
.headingContainer h4 {
  font-weight: 500;
  color: #222222;
}

.iconOpen {
  background: #f7f7f7;
  color: #786f77;
}

.iconClosed,
.iconOpen:hover {
  background: #36ae7c;
  color: #fff;
}

.iconClosed {
  rotate: 45deg;
}

.iconOpen,
.iconClosed {
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.iconOpen svg,
.iconClosed svg {
  font-size: 24px;
}

.text,
.activeText {
  overflow: hidden;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #786F77;
  transition: all 0.3s ease-in-out;
}

.activeText {
  height: 0px;
  overflow: hidden;
}
.text {
  height: 100px;
  margin-top: 30px;

}

@media screen and (max-width: 991px) {
  .accordionGrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .text{
    height: fit-content;
  }
}
@media screen and (max-width: 425px) {
  .headingContainer {

    gap: 0px;

  }
}
