.heroSectionWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline: 50px;
}
.heroSection{
    margin-top: 170px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
}
.heroSection::before{
    content: url("../../../../Assets/images/textBg.png");
    z-index: -100;
    position: absolute;
    top: 50px;
    left: 0;
}
.textSide{
    width: 630px;
    position: relative;
}
.smallTitle{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #222222;
}
.title{
    font-family: "Syne";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 3 9px;
    color: #222222;
}
.content{
    margin-top: 48px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #444444;
}
.imageSide{
    position: relative;
}
.imageSide::after{
    content: "";
    position: absolute;
    mix-blend-mode: normal;
    border: 250px solid #CEFFEB;
    filter: blur(65px);
    transform: rotate(150deg);
    right: 0;
    z-index: -1;
    bottom: 0px;
    overflow: hidden;
}
.image{
    width: 100%;
    vertical-align: bottom;
}
.button{
    margin-top: 42px;
    width: 154px;
    height: 54px;
    left: 101px;
    top: 450px;
    background: #36AE7C;
    border: 1px solid #36AE7C;
    border-radius: 68px;
}

@media screen and (max-width:1390px){
    .textSide{
        width: 50%;
    }
    .imageSide{
        width: 50%;
    }
    .image{
        width: 100%;
    }
}

@media screen and (max-width:991px) {
    .heroSection{
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;
    }
    .textSide{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .heroSection::before{
        content: url("");
    }
    .imageSide{
        width: 100%;
        margin-bottom: 50px;
    }
    .imageSide::after{
        border: 200px solid #CEFFEB;
    }
}

@media screen and (max-width:768px) {
    .imageSide::after{
        border: 100px solid #CEFFEB;
    }
    .heroSectionWrapper{
        margin-inline: 30px;
    }
}

@media screen and (max-width:425px) {
    .smallTitle{
        font-size: 18px;
    }
    .title{
        font-size: 24px;
    }
    .content{
        font-size: 16px;
        margin-top: 20px;
    }
    .button{
        margin-top: 22px;
    }
    
}