.aboutUsWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FBFBFB;
    width: 100%;
}
.aboutUs{
    max-width: 1440px;
    padding-block: 115px;
    padding-inline: 100px ;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
}
.heading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #36AE7C;
}
.title{
    font-family: "Syne";

    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    /* identical to box height */
    color: #222222;
}
.content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #444444;
}
.contentOne{
    margin-bottom: 30px;
}
@media screen and (max-width:991px) {

    .aboutUs{
        grid-template-columns: 1fr;
        padding-inline: 10px;
        padding-block: 50px;
    }
    .titleWrap,.content{
        text-align: center;
    }
    .content{
        margin-top: 30px;

    }
}

@media screen and (max-width:425px) {
    .contentOne,.contentTwo{
        font-size: 16px;       
    }
    .heading{
        font-size: 13px;
    }
    .title{
        font-size: 28px;
    }
}