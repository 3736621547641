.wrapper{
    position: relative;
}
.topWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 3%;
    bottom: 3%;
    background-color: #36AE7C;
    width: 60px ;
    height: 60px;
    z-index: 1000;
    border-radius: 50%;
    color: white;
    transition: 0.3s;
}
.icon{
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.topWrapper:hover {
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
@media screen and (max-width: 425px) {
    .topWrapper{
        width: 40px;
        height: 40px;
    }
    .icon{
        font-size: 14px;
    }
}