@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "Syne";
  src: url("../Syne/static/Syne-Regular.ttf") format("truetype");
}

:root {
  /* Transition */
  scrollbar-width: thin;
  scrollbar-color: var(--main-color) rgba(255, 255, 255, 0) !important;

  /* Colors */
  --green-color: #36ae7c;
  --white-color: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--main-color);
  font-size: 16px;
  font-family: "Poppins";
}

html {
  scroll-behavior: smooth;
}

/* --------------- SCROLLBAR */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: #121212;
  width: 2px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  background-color: #2e2e2e;
  border-radius: 2px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

body,
input {
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  background-color: var(--body-bg);
  font-style: normal;
  font-weight: 400px;
}

img {
  -webkit-user-drag: none;
}

a {
  text-decoration: none;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0;
}

h1 {
  /* 48px  */
  font-size: 3rem;
}

h2 {
  /* 40px */
  font-size: 2.5rem;
}

h3 {
  /* 26px  */
  font-size: 1.625rem;
}

h4 {
  /* 24px  */
  font-size: 1.5rem;
}

h5 {
  /* 20px  */
  font-size: 1.25rem;
}

h6 {
  /* 18px */
  font-size: 1.125rem;
}

p {
  /* 16px */
  font-size: 1rem;
  margin: 0;
}

@media screen and (max-width: 768px) {
  :root {
    font-size: 12px;
  }
}
